import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import ApiGateway, {
    ApiFacetsResponse,
    ApiResponse,
    CustomAxiosResponse,
    PureGateway,
    PureGatewayWithoutAuth,
} from '@app/helpers/AxiosHelper';
import { leadActions, ValueOf } from '@app/helpers/LeadHelper';
import { getFormData } from '@app/helpers/ObjectHelper';

import type {
    ApiCustomerActivities,
    ApiCustomerVehicle,
    ApiLeadListingParam,
    ApiLeadReason,
    CreateSalesforceLead,
    Customer,
    ExternalData,
    LeadDetails,
    LeadsFacets,
} from '@app/crud/customers/customer.type';
import { NotesResult } from '@app/crud/note/note.type';

import { ActionsBlock } from '@app/pages/customers/Partials/ActionsBlock/ActionsBlock.type';
import { Consent, Lead } from '@app/store/customers/customers.store';
import store from '@app/store/store';

const { REACT_APP_LEAD_URL } = process.env;

const getUserData = () => store.getState().auth.userData;

export interface GetCustomersParams {
    research?: string;
    vehicle?: string;
    contactName?: string;
    contactId?: number;
    contactInfoContact?: string;
    contactCity?: string;
    dealershipKnown?: string;
    vehicleKnown?: string;
    ownerName?: string;
    page?: number;
    items?: number;
    sort?: string;
    leadStatusName?: string;
    contactUpdatedPeriod?: string;
    email?: string;
    contactIsPro?: boolean;
}

export function getCustomers(params: GetCustomersParams) {
    return ApiGateway.get<ApiResponse<Customer[]>, CustomAxiosResponse<ApiResponse<Customer[]>>>(
        `${REACT_APP_LEAD_URL}/contact`,
        {
            params,
        },
    );
}

export function getCustomersPortfolio(params: GetCustomersParams) {
    return ApiGateway.get<ApiResponse<Customer[]>, CustomAxiosResponse<ApiResponse<Customer[]>>>(
        `${REACT_APP_LEAD_URL}/contact/client-portfolio`,
        {
            params,
        },
    );
}

export function getCustomerWithActiveAction(params: GetCustomersParams) {
    return ApiGateway.get<ApiResponse<Customer[]>, CustomAxiosResponse<ApiResponse<Customer[]>>>(
        `${REACT_APP_LEAD_URL}/contact/active-action`,
        {
            params,
        },
    );
}

export async function getCustomerCounters() {
    const { count } = await ApiGateway.get<ApiResponse<[]>, CustomAxiosResponse<ApiResponse<[]>>>(
        `${REACT_APP_LEAD_URL}/contact/client-portfolio-count`,
    );
    return count;
}

interface ApiCustomer {
    error?: boolean;
    contact: Customer;
    externalData: ExternalData[] | null;
    leads: Lead[];
    differentialsVCU: [];
}

export function getCustomer(id: string) {
    return ApiGateway.get<ApiResponse<ApiCustomer>, CustomAxiosResponse<ApiResponse<ApiCustomer>>>(
        `${REACT_APP_LEAD_URL}/contact/${id}`,
    );
}

export interface ApiActionData {
    type: 'business' | 'activity';
    error?: boolean;
}

export function getActionData(id: string) {
    return ApiGateway.get<ApiResponse<ApiActionData>, CustomAxiosResponse<ApiResponse<ApiActionData>>>(
        `${REACT_APP_LEAD_URL}/contact/business?vcuId=${id}`,
    );
}

export function getEventsCustomerLife(id: string) {
    return ApiGateway.get<ApiResponse<ApiCustomerVehicle[]>, CustomAxiosResponse<ApiResponse<ApiCustomerVehicle[]>>>(
        `${REACT_APP_LEAD_URL}/contact/${id}/events/client-life`,
    );
}

export function getAllLeads(params: object) {
    return ApiGateway.get<
        ApiFacetsResponse<Lead[], LeadsFacets>,
        CustomAxiosResponse<ApiFacetsResponse<Lead[], LeadsFacets>>
    >(`${REACT_APP_LEAD_URL}/lead`, {
        params,
    });
}

export function getOneCustomerActivity(id: string) {
    return ApiGateway.get<LeadDetails, CustomAxiosResponse<LeadDetails>>(`${REACT_APP_LEAD_URL}/lead/${id}`);
}

export function getOneCustomerActivities(id: string) {
    return ApiGateway.get<ApiResponse<ApiCustomerActivities>, CustomAxiosResponse<ApiResponse<ApiCustomerActivities>>>(
        `${REACT_APP_LEAD_URL}/lead/${id}/activities`,
    );
}

export function getExportedLeads(startDate?: string, endDate?: string) {
    const params = startDate !== null && endDate !== null ? `?startDate=${startDate}&endDate=${endDate}` : '';

    return ApiGateway.get<string, BlobPart>(`${REACT_APP_LEAD_URL}/lead/export/closed-lead-fid${params}`, {
        responseType: 'arraybuffer',
    }).then((response) => {
        const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `closed-leads-fid-export-${dayjs().format('YYYY-MM-DD')}.xlsx`);
    });
}

export function getCustomerNotes(id: number) {
    return ApiGateway.get<NotesResult, CustomAxiosResponse<NotesResult>>(`${REACT_APP_LEAD_URL}/contact/${id}/notes`);
}

export function getCustomerActions(id: number) {
    return ApiGateway.get<ApiResponse<ActionsBlock>, CustomAxiosResponse<ApiResponse<ActionsBlock>>>(
        `${REACT_APP_LEAD_URL}/contact/${id}/bloc_action`,
    );
}

export interface Action {
    adminForce?: boolean;
    reason?: string;
    result?: string;
    actionType: string;
    contactId: number;
    emailType?: string;
    object?: string;
    content?: string;
    recipient?: string;
    lead?: {
        id: number;
        type?: string;
        source?: string;
        origin?: string;
        dealershipId?: string;
        dealershipName?: string;
    };
    user: {
        id: number;
        firstname: string;
        lastname: string;
        email: string;
    };
    dealership?: {
        id: string;
        name: string;
    };
    newOwner?: {
        id: string;
        firstname: string;
        lastname: string;
        email: string;
    };
}

interface ActionReturn {
    leads: Lead[];
    lastLeadId: number;
    lead?: Lead;
}

export function addAction(data: Action, form = false) {
    return ApiGateway.post<ApiResponse<ActionReturn>, CustomAxiosResponse<ApiResponse<ActionReturn>>>(
        `${REACT_APP_LEAD_URL}/action`,
        form ? getFormData(data) : data,
    );
}

export function addActionConsignReportController(data: { lead: number; comment: string }) {
    return ApiGateway.post<ApiResponse<void>, CustomAxiosResponse<ApiResponse<void>>>(
        `${REACT_APP_LEAD_URL}/action/consign-report-controlled`,
        data,
    );
}

export function addCustomer(data: Customer) {
    const user = getUserData();

    return ApiGateway.post<ApiResponse<Customer>, CustomAxiosResponse<ApiResponse<Customer>>>(
        `${REACT_APP_LEAD_URL}/contact`,
        {
            ...data,
            user: {
                id: user.id,
                firstname: user.first_name,
                lastname: user.last_name,
            },
        },
    );
}

export function updateCustomerById(id: string, data: Customer) {
    return ApiGateway.put<ApiResponse<Customer>, CustomAxiosResponse<ApiResponse<Customer>>>(
        `${REACT_APP_LEAD_URL}/contact/${id}`,
        data,
    );
}

export function getAllParams(params?: object) {
    return ApiGateway.get<ApiLeadListingParam, CustomAxiosResponse<ApiLeadListingParam>>(
        `${REACT_APP_LEAD_URL}/listing-param`,
        {
            params,
        },
    );
}

export function getAlreadyExistedCustomers(params: object) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/contact/check-data`, {
        params,
    });
}

interface LeadSearchArgs {
    limit: number;
    page: number;
    leadType: string[];
    leadTypeGroup: string[];
    leadSource: string[];
    leadStatus: string[];
    created: string;
    createdBy: string;
    dealershipId: string;
    dealership: string;
    user: string;
    contact: string;
    vehicleIdentifier: string;
    noLeadParent: boolean;
    flags: string[];
    sort: string;
    receiptedPeriod: string;
    scheduleAppointmentPeriod: string;
    contactZipcode: string;
    status: string;
}

export function getLeadByStatus(data: LeadSearchArgs) {
    const status = data?.status;
    delete data?.status;

    if (data?.createdBy) {
        data.createdBy = data.createdBy.trim();
    }

    const filteredData = Object.fromEntries(
        Object.entries(data).filter(
            ([_, value]) => value !== null && value !== undefined && !(Array.isArray(value) && value.length === 0),
        ),
    );
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/lead/status/${status}`, {
        ...filteredData,
    });
}

export function getLeadReason() {
    return ApiGateway.get<ApiResponse<ApiLeadReason>, CustomAxiosResponse<ApiResponse<ApiLeadReason>>>(
        `${REACT_APP_LEAD_URL}/lead-reason`,
    );
}

export function getCustomerReviews(email: string) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/review/${email}`, {
        params: {
            orderBy: 'dateReview_DESC',
        },
    });
}

export function getCustomerEvents(params: { id: string; start?: number; items?: number; isClientLife?: boolean }) {
    const id = params?.id;
    delete params?.id;

    return ApiGateway.get(`${REACT_APP_LEAD_URL}/contact/${id}/events`, {
        params,
    });
}

export function getCustomerEventById(id: string) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/contact/events/${id}`);
}

export function associateContactToLead(leadId: string, contactId: string) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/lead/${leadId}/associate-contact/${contactId}`);
}

interface LeadFinance {
    financialType: string;
    financialAgency: string;
    financialBudget: number;
    financialInput: number;
    financialRent: number;
    comment: string;
    interest: string;
}

export function addLeadFinance(leadId: string, data: LeadFinance) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/lead/${leadId}/finance`, data);
}

export interface LeadPartExchange {
    estimatedPrice: number;
    priceRebate: number;
    partExchangeReference: null;
    registration: null;
    make: string;
    model: string;
    version: null;
    dateFirstRegistration: string;
    mileage: null;
    fuelType: null;
    comment: null;
    leadId: number;
    image: string;
}

export function addLeadPartExchange(leadId: string, data: LeadPartExchange) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/part-exchange`, {
        ...data,
        leadId,
    });
}

export function getAFRPartExchangeByReference(reference: string) {
    return ApiGateway.get<ApiResponse<LeadPartExchange>, CustomAxiosResponse<ApiResponse<LeadPartExchange>>>(
        `${REACT_APP_LEAD_URL}/part-exchange/reference/${reference}`,
    );
}

export async function getLeadNewCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/lead/new`);
    return result;
}

export async function getLeadActionToDoCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/lead/action_to_do`);
    return result;
}

export async function getLeadActionToComeCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/lead/action_to_come`);
    return result;
}

export async function getLeadAllCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/lead/all`);
    return result;
}

export async function getLeadCloseCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/lead/close`);
    return result;
}

export async function getAchatCashNewCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/achat-cash/new`);
    return result;
}

export async function getAchatCashActionToDoCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/achat-cash/action_to_do`);
    return result;
}

export async function getAchatCashActionToComeCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/achat-cash/action_to_come`);
    return result;
}

export async function getAchatCashAllCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/achat-cash/all`);
    return result;
}

export async function getAchatCashCloseCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/achat-cash/close`);
    return result;
}

export async function getAchatCashStockCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/achat-cash/stock`);
    return result;
}

export async function getFluidityInProgressCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/lead-fluidity/in_progress`);
    return result;
}

export async function getFluidityCloseCounter() {
    // @ts-ignore
    const { result } = await ApiGateway.get(`${REACT_APP_LEAD_URL}/counter/lead-fluidity/close`);
    return result;
}

interface PartExchange {
    registration: string;
    vin: string;
    make: string;
    model: string;
    version: string;
    dateFirstRegistration: string;
    mileage: number;
    fuelType: string;
    gearbox: null;
    vehicleType: string;
    registrationCertificate: null;
    registrationCertificateAbsenceReason: string;
    coHolder: null;
}

export function updateLeadPartExchange(id: number, data: PartExchange) {
    return ApiGateway.put(`${REACT_APP_LEAD_URL}/part-exchange/${id}`, {
        ...data,
    });
}

type PartExchangeValuationParams = {
    repairCosts: number;
    finalOffer: number;
};

export function updateLeadPartExchangeValuation(id: number, data: PartExchangeValuationParams) {
    return ApiGateway.put(`${REACT_APP_LEAD_URL}/part-exchange/${id}/valuation`, {
        ...data,
    });
}

export function deleteLeadPartExchange(id: number) {
    return ApiGateway.delete(`${REACT_APP_LEAD_URL}/part-exchange/${id}`);
}

export interface Picture {
    url: string;
}

export function getPartExchangePictures(nationalVehicleCode: string, pointOfView?: string) {
    return PureGateway.get<Picture[]>(`${REACT_APP_LEAD_URL}/part-exchange/picture`, {
        params: {
            nationalVehicleCode,
            pointOfView,
            list: true,
        },
    });
}

export function updateFinance(leadId: string, id: string, data: LeadFinance) {
    return ApiGateway.put(`${REACT_APP_LEAD_URL}/lead/${leadId}/finance/${id}`, {
        ...data,
    });
}

export function getEvent(id: string) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/event/${id}`);
}

export function getEventAttachment(id: string) {
    return PureGateway.get(`${REACT_APP_LEAD_URL}/event/${id}/attachment`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
        },
    });
}

export function rollBackLead(id: number) {
    return ApiGateway.delete(`${REACT_APP_LEAD_URL}/lead/${id}/rollback`);
}

export function batchStatusUpdateLead(leadIds: number[], action: ValueOf<typeof leadActions>) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/admin/lead/batch-status-update`, {
        leadIds,
        reason: 'forced',
        result: 'Forcé par un administrateur',
        success: action === leadActions.close_lead_won,
        actionType: action,
    });
}

export function checkLeadAudiCallTrackingStates(id: string) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/lead-audi/check/${id}`);
}

interface RpaActionParams {
    action: string;
    app: string;
    contactId: number;
    rpaId?: string;
    workflowId?: number;
}

export function rpaAction(data: RpaActionParams) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/rpa/transfer`, {
        ...data,
    });
}

export function getRpaWorkflow(idWorkflow: string) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/rpa/workflow/${idWorkflow}`);
}

type ConsentByToken = {
    consents: {
        email: boolean;
        sms: boolean;
        phone: boolean;
        post: boolean;
    };
    dealership: {
        name: string;
        address: string;
        zipcode: string;
        city: string;
        context: string;
    };
};

export function getConsentByToken(token: string) {
    return PureGatewayWithoutAuth.get<ApiResponse<ConsentByToken>, CustomAxiosResponse<ApiResponse<ConsentByToken>>>(
        `${REACT_APP_LEAD_URL}/consent/${token}`,
    );
}

type UpdatedConsent = {
    id: number;
    name: string;
    slug: string;
    refDealership: string;
    gdprSms: boolean;
    gdprPhone: boolean;
    gdprPost: boolean;
    gdprEmail: boolean;
    clientToken: string;
    created: string;
    updated: string;
};

export function updateConsentByToken(token: string, data: Consent) {
    return PureGatewayWithoutAuth.put<ApiResponse<UpdatedConsent>, CustomAxiosResponse<ApiResponse<UpdatedConsent>>>(
        `${REACT_APP_LEAD_URL}/consent/${token}`,
        data,
    );
}
export function createCustomerNote(data: { message: string; type: string; contactId: number }) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/contact-note`, data);
}

export interface InStockVehicle {
    sku: string;
}

export interface OutStockVehicle {
    make: string;
    model: string;
    fuelType?: string;
    gearsType?: string;
}

export type ReceptionistLeadPayload = {
    contact: number;
    dealership: string;
    leadType: string;
    customerProject: string;
    autouserSource?: number;
    vehicles?: (InStockVehicle | OutStockVehicle)[] | null;
    appointment: {
        subject: string;
        dateStart: string;
        dateEnd: string;
        comment: string;
        notifyCustomer: boolean;
    };
};

export function createReceptionistLead(data: ReceptionistLeadPayload) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/action/create-lead/receptionist`, data);
}

export type ReceptionistNotePayload = {
    contactId: number;
    type: 'sales';
    message: string;
    noteTypeSlug: string;
    dealershipSource: string;
    referentId?: number;
    team: string;
};

export function createReceptionistNote(data: ReceptionistNotePayload) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/contact-note/add-with-type`, data);
}

export function createSalesforceLead(id: number) {
    return ApiGateway.post<ApiResponse<CreateSalesforceLead>, CustomAxiosResponse<ApiResponse<CreateSalesforceLead>>>(
        `${REACT_APP_LEAD_URL}/lead/${id}/create-salesforce-lead`,
    );
}

export function createContactFromVcu(vcuId: string) {
    return ApiGateway.post<ApiResponse<Customer>, CustomAxiosResponse<ApiResponse<Customer>>>(
        `${REACT_APP_LEAD_URL}/contact/create/from-vcu-id/${vcuId}`,
    );
}

export function createContactFromLead(leadId: string) {
    return ApiGateway.post<ApiResponse<Customer>, CustomAxiosResponse<ApiResponse<Customer>>>(
        `${REACT_APP_LEAD_URL}/contact/create/from-lead-origin/${leadId}`,
    );
}

export function bernardConsentUpdateRequest(contactId: number) {
    return ApiGateway.post<ApiResponse<{ success: boolean }>, CustomAxiosResponse<ApiResponse<{ success: boolean }>>>(
        `${REACT_APP_LEAD_URL}/consent/bernard-update/${contactId}`,
    );
}
