import dayjs from 'dayjs';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import store from '@app/store/store';

const getUserData = () => store.getState().auth.userData;

export const GDPR_NAME = 'EFF';

export const GDPR_FIELDS = [
    {
        slug: 'gdprEmail',
        map: 'email',
        label: 'TRANSLATOR.CONSENT_EMAIL',
    },
    {
        slug: 'gdprPhone',
        map: 'phone',
        label: 'TRANSLATOR.CONSENT_PHONE',
    },
    {
        slug: 'gdprSms',
        map: 'sms',
        label: 'TRANSLATOR.CONSENT_SMS',
    },
    {
        slug: 'gdprPost',
        map: 'post',
        label: 'TRANSLATOR.CONSENT_POST',
    },
];

export const GDPR_GROUPS = {
    gdprAutosphere: 'AUTOSPHERE',
    gdprDealerships: 'DEALERSHIPS',
    gdprSecurycar: 'SECURYCAR',
};

/**
 * Get customer GDPR
 *
 * @param name
 * @param gdpr
 * @returns {{refDealership: *, gdprEmail: boolean, gdprPhone: boolean, name, gdprSms: boolean, gdprAddress: boolean}}
 */
export const getCustomerGdpr = (name, gdpr) => {
    const expectedValues = ['true', 'false', true, false];
    return {
        name,
        gdprEmail: expectedValues.includes(gdpr?.gdprEmail) ? String(gdpr?.gdprEmail) === 'true' : null,
        gdprPhone: expectedValues.includes(gdpr?.gdprPhone) ? String(gdpr?.gdprPhone) === 'true' : null,
        gdprSms: expectedValues.includes(gdpr?.gdprSms) ? String(gdpr?.gdprSms) === 'true' : null,
        gdprPost:
            expectedValues.includes(gdpr?.gdprPost) || expectedValues.includes(gdpr?.gdprAddress)
                ? String(gdpr?.gdprPost) === 'true' || String(gdpr?.gdprAddress) === 'true'
                : null,
        ...(gdpr?.refDealershipId ? { refDealership: gdpr?.refDealershipId } : {}),
        ...(gdpr?.refDealership ? { refDealership: gdpr.refDealership } : {}),
    };
};

/**
 * Get customer GDPRS
 *
 * @param customer
 * @param withDisplay
 * @returns {unknown[]}
 */
export const getCustomerGdprs = (customer, withDisplay = false) => {
    const userData = getUserData();
    let gdpr = {};

    // Add VCU consents
    Object.keys(GDPR_GROUPS).forEach((group) => {
        const customerGroups = customer[group];

        if (customerGroups) {
            if (group === 'gdprDealerships') {
                if (Array.isArray(customerGroups)) {
                    customerGroups.forEach((customerGroup) => {
                        gdpr[customerGroup.refDealershipName] = getCustomerGdpr(
                            customerGroup.refDealershipName,
                            customerGroup,
                        );
                    });
                }
            } else {
                const name = GDPR_GROUPS[group];
                gdpr[name] = getCustomerGdpr(name, customerGroups);
            }
        }
    });

    // Add customer consents
    if (customer?.consents) {
        customer.consents.forEach((consent) => {
            gdpr[consent.name] = getCustomerGdpr(consent.name, consent);
        });
    }

    // Add created / updated consents
    if (customer?.new_consents) {
        Object.entries(customer.new_consents).forEach(([consent, value]) => {
            const splittedConsent = consent.split('_');
            const dealershipId = splittedConsent[0];
            const dealership = Object.entries(userData.dealerships).find(([code]) => code === dealershipId);
            if (dealership === undefined) {
                return;
            }

            const name = dealership[1]?.title;
            const type = splittedConsent[splittedConsent.length - 1];

            if (!gdpr[dealershipId]) {
                gdpr[dealershipId] = {};
            }

            gdpr[dealershipId] = getCustomerGdpr(name, {
                ...gdpr[dealershipId],
                [type]: value,
                refDealership: dealershipId,
            });
        });
    }

    // Object to array
    gdpr = Object.values(gdpr);

    if (withDisplay) {
        const dealershipConsents = [];
        gdpr.slice()
            .reverse()
            .forEach((consent, index, object) => {
                if (consent?.refDealership) {
                    dealershipConsents.unshift(consent);
                    gdpr.splice(object.length - 1 - index, 1);
                }
            });

        dealershipConsents.sort((a, b) => (a.name > b.name ? 1 : -1));
        gdpr.push(dealershipConsents);
    }

    // Set Securycar first
    const gdprSecurycarIdx = gdpr.findIndex((element) => element.name === GDPR_GROUPS.gdprSecurycar);
    if (gdprSecurycarIdx !== -1) {
        const gdprSecurycar = gdpr[gdprSecurycarIdx];
        gdpr.splice(gdprSecurycarIdx, 1);
        gdpr.unshift(gdprSecurycar);
    }

    // Set Autosphere first (before Securycar)
    const gdprAutosphereIdx = gdpr.findIndex((element) => element.name === GDPR_GROUPS.gdprAutosphere);
    if (gdprAutosphereIdx !== -1) {
        const gdprAutosphere = gdpr[gdprAutosphereIdx];
        gdpr.splice(gdprAutosphereIdx, 1);
        gdpr.unshift(gdprAutosphere);
    }

    return gdpr;
};

/**
 * Map customer gdpr
 *
 * @param gdprs
 */
export const mapCustomerGdpr = (gdprs) => {
    gdprs.forEach((gdpr) => {
        GDPR_FIELDS.forEach((field) => {
            gdpr[field.map] = gdpr[field.slug];
            delete gdpr[field.slug];
        });
    });

    return gdprs;
};

/**
 * Get customer GDPR
 *
 * @returns {{refDealership: *, gdprEmail: boolean, gdprPhone: boolean, name, gdprSms: boolean, gdprAddress: boolean}}
 * @param customer
 */
export const mapVcuCustomer = (customer) => {
    if (!customer) return {};

    const gdpr = getCustomerGdprs(customer);

    return {
        additionalAddress: customer?.additionalAddress || '',
        address: customer?.address || '',
        autosphereId: customer?.autosphereId ? parseInt(customer.autosphereId, 10) : null,
        birthday:
            customer?.birthday && dayjs(customer.birthday).isValid()
                ? dayjs(customer.birthday).format('YYYY-MM-DD')
                : '',
        city: customer?.city || '',
        civility: customer?.civility ? customer.civility.toLowerCase() : '',
        country: customer?.country || '',
        dealershipKnow: customer?.dealershipKnow || null,
        deliveryId: customer?.deliveryId ? parseInt(customer.deliveryId, 10) : null,
        email: customer?.email || '',
        firstname: customer?.firstname || '',
        isClient: customer?.isClient || false,
        lastname: customer?.lastname || '',
        mobile: customer?.mobile || '',
        phone: customer?.phone || '',
        vcuId: customer?.vcuId || null,
        vehicleKnow: customer?.vehicleKnow || [],
        zipcode: customer?.zipcode || '',
        gdpr: [...(mapCustomerGdpr(gdpr) ?? []), ...(customer?.gdpr ?? [])],
        md5: customer?.md5 || null,
        isPro: customer?.isPro || null,
        siren: customer?.siren || null,
        siret: customer?.siret || null,
        ape: customer?.ape ? { code: customer?.ape?.code, value: customer?.ape?.value } : null,
        companyName: customer?.companyName || null,
        companyLegalStatus: customer?.companyLegalStatus || null,
        companyPhone: customer?.companyPhone || null,
        proPhone: customer?.proPhone || null,
        function: customer?.function || null,
        role: customer?.role || null,
    };
};

/**
 * Get customer civility
 *
 * @param civility
 * @returns {string|null}
 */
export const getCivility = (civility) => {
    if (civility) {
        switch (civility.toLowerCase()) {
            case 'mr':
            case 'm':
                return 'M.';
            case 'mme':
                return 'Mme.';
            default:
                return '';
        }
    }
    return null;
};

export const mapCustomerFieldSelection = (new_customer, existing_customer) => {
    const fields = [
        {
            label: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.CIVILITY' }),
            field: 'civility',
        },
        {
            label: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.FIRSTNAME' }),
            field: 'firstname',
        },
        {
            label: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.LASTNAME' }),
            field: 'lastname',
        },
        {
            label: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.PHONE' }),
            field: 'phone',
        },
        {
            label: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.MOBILE' }),
            field: 'mobile',
        },
        {
            label: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.EMAIL' }),
            field: 'email',
        },
    ];

    return fields?.reduce((acc, field) => {
        if (new_customer?.[field?.field]?.toLowerCase?.() !== existing_customer?.[field?.field]?.toLowerCase?.()) {
            acc.push({
                ...field,
                old_value: new_customer?.[field?.field] || '',
                new_value: existing_customer?.[field?.field] || '',
            });
        }

        return acc;
    }, []);
};

/**
 * Check if any Gdpr is defined
 *
 * @param client
 */
export const hasConsents = (client) => {
    const consents = client?.consents;

    if (!Array.isArray(consents) || !consents.length) {
        return false;
    }

    return !!consents.find(
        (consent) =>
            consent.gdprEmail !== null ||
            consent.gdprPhone !== null ||
            consent.gdprPost !== null ||
            consent.gdprSms !== null,
    );
};

/**
 * Check if any Gdrp is set to true
 */
export const getCustomerAgeRange = () => {
    const year = new Date().getFullYear();
    const startYear = year - 100;
    const endYear = year - 18;
    return { startYear, endYear };
};

/**
 * Check if vehicle is in dealership
 *
 * @param apvRequest - The request object to check for vehicle presence
 * @returns {boolean} - True if the vehicle is present, false otherwise
 */
export const getVehiclePresence = (apvRequest) => !!apvRequest;
