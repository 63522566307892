import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import {
    associateContactToLead,
    createContactFromLead,
    createContactFromVcu,
    getCustomers,
} from '@app/crud/customers/customer.crud';
import { mapCustomers } from '@app/crud/mapping/customer.map';

import ROUTES from '@app/router/Routes';
import { actions } from '@app/store/modal/modal.store';
import store from '@app/store/store';

import { getHistory } from '@app/partials/content/GlobalHistory';
import toast from '@app/partials/content/Toast';

export const associateContact = (leadId, CustomerId, takeLead) => {
    associateContactToLead(leadId, CustomerId)
        .then((response) => {
            if (response?.result) {
                store.dispatch(actions.closeModals());
                getHistory().push(
                    routeTo(
                        ROUTES.CUSTOMER.PATH,
                        {
                            id: CustomerId,
                        },
                        {
                            action: takeLead ? 'take' : 'viewLead',
                            leadId,
                        },
                    ),
                );
            } else {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMERS.ADD.FAILED' }),
                });
            }
        })
        .catch(() => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.ADD.FAILED' }),
            });
        });
};

export const associateContactToLeadHandler = (lead, customer, takeLead) => {
    if (customer?.vcuId && !customer?.id) {
        return createContactFromVcu(customer.vcuId).then((response) => {
            if (!response?.result?.error) {
                associateContact(lead?.id, response?.result?.id, takeLead);
            } else {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMERS.ADD.FAILED' }),
                });
            }
        });
    } else {
        return associateContact(lead?.id, customer?.id, takeLead);
    }
};

export const createContactFromLeadHandler = (lead, takeLead) => {
    createContactFromLead(lead?.id)
        .then((response) => {
            if (response?.result) {
                store.dispatch(actions.closeModals());
                getHistory().push(
                    routeTo(
                        ROUTES.CUSTOMER.PATH,
                        {
                            id: response?.result?.id,
                        },
                        {
                            action: takeLead ? 'take' : 'viewLead',
                            leadId: lead?.id,
                        },
                    ),
                );
            } else {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMERS.ADD.FAILED' }),
                });
            }
        })
        .catch(() => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.ADD.FAILED' }),
            });
        });
};

export const checkIdenticalData = (lead, takeLead = true, callback) => {
    if (lead?.contactOrigin) {
        if (callback) {
            callback(true);
        }

        getCustomers({
            email: lead?.contactOrigin?.email,
        }).then((response) => {
            if (callback) {
                callback(false);
            }

            if (response?.result) {
                store.dispatch(
                    actions.modalChange('create_already_exist', {
                        customers: mapCustomers(Object.values(response?.result)),
                        customer: lead?.contactOrigin,
                        onCustomerCreate: () => createContactFromLeadHandler(lead, takeLead),
                        onCustomerAssociation: (contact) => associateContactToLeadHandler(lead, contact, takeLead),
                    }),
                );
            }
        });
    }
};
