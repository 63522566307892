import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getBrands, getModels, getVersions } from '../../crud/customers/stock.crud';
import { Brand, Model, Versions } from '../../crud/customers/stock.type';
import { removeDuplicate } from '../../helpers/ArrayHelper';
import { CustomAxiosResponse } from '../../helpers/AxiosHelper';
import useFetch from '../../hooks/useFetch';
import ErrorForm from '../layout/ErrorForm';
import toast from './Toast';

type BrandModelVersionProps = {
    onLoading: Function;
    onBrandUpdated?: Function;
    onModelUpdated?: Function;
    onVersionUpdated?: Function;
    onBrandsUpdated?: Function;
    onModelsUpdated?: Function;
    onVersionsUpdated?: Function;
    brandLabel?: string;
    modelLabel?: string;
    versionLabel?: string;
    modelPersonalizedLabel?: string;
    modelRequired?: boolean;
    noModel?: boolean;
    noVersion?: boolean;
    defaultsValues?: {
        make: string;
        model: string;
        version: string;
        dateFirstRegistration: string;
    };
    editableModel?: boolean;
    textFieldVersion?: boolean;
    containerClassName?: string;
    fieldClassName?: string;
};
const BrandModelVersion = ({
    onLoading,
    onBrandUpdated,
    onModelUpdated,
    onVersionUpdated,
    onBrandsUpdated,
    onModelsUpdated,
    onVersionsUpdated,
    brandLabel,
    modelLabel,
    versionLabel,
    modelRequired,
    modelPersonalizedLabel,
    noModel,
    noVersion,
    defaultsValues,
    editableModel = false,
    textFieldVersion = false,
    containerClassName = '',
    fieldClassName = '',
}: BrandModelVersionProps) => {
    const { register, setValue, errors, control, getValues } = useFormContext();
    const [selectedModel, setSelectedModel] = useState(defaultsValues?.model || '');
    const defaultFieldsValues = useRef<{
        make: string;
        model: string;
        version: string;
        dateFirstRegistration: string;
    }>({ ...defaultsValues });
    const intl = useIntl();

    const {
        fetch: fetchBrands,
        data: brands,
        loading: loadingBrands,
        error: errorBrands,
    } = useFetch<void, Brand[]>({
        fetchAction: getBrands,
        resultInterceptor: (response: CustomAxiosResponse<Brand[]>) => {
            const result = (response ? removeDuplicate(response, 'MakeCode') : []) as Brand[];

            if (onBrandsUpdated) {
                onBrandsUpdated(result);
            }

            if (defaultFieldsValues.current.make) {
                const brand = result.find((brand) => {
                    return brand.Name === defaultFieldsValues.current.make;
                });

                const date = getValues().dateFirstRegistration.split('-');
                fetchModels({
                    makeCode: brand.MakeCode,
                    month: date[1],
                    year: date[0],
                });
                defaultFieldsValues.current.make = '';
            }

            return result;
        },
    });
    const {
        fetch: fetchModels,
        data: models,
        loading: loadingModels,
    } = useFetch<{ makeCode: string; month?: string; year?: string }, Model[]>({
        fetchAction: getModels,
        resultInterceptor: (response: CustomAxiosResponse<Model[]>) => {
            let result = response ? (removeDuplicate(response, 'Name') as Model[]) : [];
            result.sort((a, b) => a?.Name?.localeCompare(b?.Name));

            if (noModel) {
                result = [
                    ...result,
                    {
                        VehicleTypeCode: '1002',
                        MakeCode: '1000',
                        ModelCode: '1001',
                        Name: intl.formatMessage({
                            id: 'CUSTOMERS.MODAL.PART_EXCHANGE.NONE',
                        }),
                        NameExtended: '00000',
                    },
                ];
            }
            if (onModelsUpdated) {
                onModelsUpdated(result);
            }

            if (defaultFieldsValues.current.model) {
                const model = result.find((model) => {
                    return model.Name === defaultFieldsValues.current.model;
                });

                if (model) {
                    const date = getValues().dateFirstRegistration.split('-');
                    fetchVersions({
                        makeCode: parseInt(model.MakeCode),
                        modelCode: parseInt(model.ModelCode),
                        month: date[1],
                        year: date[0],
                    });
                    defaultFieldsValues.current.model = '';
                } else {
                    setValue(
                        'model',
                        intl.formatMessage({
                            id: 'CUSTOMERS.MODAL.PART_EXCHANGE.NONE',
                        }),
                    );
                }
            }

            return result;
        },
    });
    const {
        fetch: fetchVersions,
        data: versions,
        loading: loadingVersions,
    } = useFetch<{ makeCode: number; modelCode: number; month: string; year: string }, Versions>({
        fetchAction: getVersions,
        resultInterceptor: (response: CustomAxiosResponse<Versions>) => {
            if (onVersionsUpdated) {
                onVersionsUpdated(response || []);
            }

            if (defaultFieldsValues.current.version) {
                defaultFieldsValues.current.version = '';
            }

            return response || [];
        },
    });

    const isOtherModel = (modelName: string) => {
        return (
            !models.some((model) => model.Name === modelName) ||
            modelName === intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.NONE' })
        );
    };

    useEffect(() => {
        fetchBrands();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        defaultFieldsValues.current = defaultsValues;
        fetchBrands();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultsValues.make, defaultsValues.model, defaultsValues.version]);

    useEffect(() => {
        onLoading(loadingBrands || loadingModels || loadingVersions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingBrands, loadingModels, loadingVersions]);

    useEffect(() => {
        if (errorBrands) {
            toast({
                variant: 'danger',
                message: intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.ERROR.LOAD_BRANDS' }),
            });
        }
    }, [errorBrands]);

    const onBrandChange = (e: any) => {
        if (onBrandUpdated) {
            onBrandUpdated(e);
            return;
        }

        const brand = brands.find((brand) => {
            return brand.Name === e.value;
        });

        if (brand) {
            const date = getValues().dateFirstRegistration;
            fetchModels({
                makeCode: brand.MakeCode,
                month: date ? date.split('-')[1] : null,
                year: date ? date.split('-')[0] : null,
            });
        }

        setValue('model', '');
    };

    const onModelChange = (e: any) => {
        if (onModelUpdated) {
            onModelUpdated(e);
            return;
        }

        if (!isOtherModel(e.value)) {
            const model = models.find((model) => {
                return model.Name === e.value;
            });

            if (model) {
                const date = getValues().dateFirstRegistration;
                fetchVersions({
                    makeCode: parseInt(model.MakeCode),
                    modelCode: parseInt(model.ModelCode),
                    month: date ? date.split('-')[1] : null,
                    year: date ? date.split('-')[0] : null,
                });
            }
        }

        setValue('version', '');
        setSelectedModel(e.value);
    };

    const onVersionChange = (e: any) => {
        if (onVersionUpdated) {
            onVersionUpdated(e);
        }
    };

    return (
        <div className={containerClassName}>
            <Form.Group className={fieldClassName}>
                {brandLabel && <Form.Label>{brandLabel}</Form.Label>}
                <Controller
                    control={control}
                    name="make"
                    defaultValue={defaultsValues.make || ''}
                    rules={{ required: true }}
                    render={(props) => {
                        return (
                            <Dropdown
                                value={props.value}
                                options={brands.map((brand) => {
                                    return {
                                        value: brand.Name,
                                        label: brand.Name,
                                    };
                                })}
                                onChange={(e) => {
                                    props.onChange(e.value);
                                    onBrandChange(e);
                                }}
                                optionLabel="label"
                                optionValue="value"
                                placeholder={intl.formatMessage({
                                    id: 'CUSTOMERS.MODAL.APE_DROPDOWN.TITLE',
                                })}
                            />
                        );
                    }}
                />
                <ErrorForm errors={errors} name="make" />
            </Form.Group>
            <Form.Group className={fieldClassName}>
                {modelLabel && <Form.Label>{modelLabel}</Form.Label>}
                <Controller
                    control={control}
                    name="model"
                    defaultValue={defaultsValues.model || ''}
                    rules={{ required: true }}
                    render={(props) => {
                        return (
                            <Dropdown
                                value={props.value}
                                options={models.map((model) => {
                                    return {
                                        value: model.Name,
                                        label: model.Name,
                                    };
                                })}
                                onChange={(e) => {
                                    props.onChange(e.value);
                                    onModelChange(e);
                                }}
                                editable={editableModel}
                                optionLabel="label"
                                optionValue="value"
                                placeholder={intl.formatMessage({
                                    id: 'CUSTOMERS.MODAL.APE_DROPDOWN.TITLE',
                                })}
                            />
                        );
                    }}
                />
                <ErrorForm errors={errors} name="model" />
            </Form.Group>
            {isOtherModel(selectedModel) && selectedModel !== '' && !editableModel && (
                <Form.Group className={fieldClassName}>
                    {modelPersonalizedLabel && <Form.Label>{modelPersonalizedLabel}</Form.Label>}
                    <Form.Control
                        name="modelOther"
                        ref={register({
                            required: modelRequired
                                ? intl.formatMessage({
                                      id: 'FORM.ERROR.REQUIRED',
                                  })
                                : false,
                        })}
                    />
                </Form.Group>
            )}
            {!noVersion && (
                <Form.Group className={fieldClassName}>
                    {versionLabel && <Form.Label>{versionLabel}</Form.Label>}
                    {textFieldVersion ? (
                        <Form.Control
                            name="version"
                            ref={register({
                                required: !isOtherModel(getValues().model),
                            })}
                        />
                    ) : (
                        <Controller
                            control={control}
                            name="version"
                            defaultValue=""
                            rules={{
                                required: !isOtherModel(getValues().model),
                            }}
                            render={(props) => {
                                return (
                                    <Dropdown
                                        value={props.value}
                                        options={versions?.version?.map((version) => {
                                            return {
                                                value:
                                                    version.Name +
                                                    (version.TrimLineName ? ' ' + version.TrimLineName : ''),
                                                label:
                                                    version.Name +
                                                    (version.TrimLineName ? ' ' + version.TrimLineName : ''),
                                            };
                                        })}
                                        disabled={isOtherModel(getValues().model)}
                                        onChange={(e) => {
                                            props.onChange(e.value);
                                            onVersionChange(e);
                                        }}
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder={intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.APE_DROPDOWN.TITLE',
                                        })}
                                    />
                                );
                            }}
                        />
                    )}
                </Form.Group>
            )}
        </div>
    );
};

export default BrandModelVersion;
