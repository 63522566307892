import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import KTUtil from '@src/_metronic/_assets/js/util';

import ConsentConfirmation from '@app/pages/customers/Modals/ConsentConfirmation';
import DisplayConsentValues from '@app/pages/customers/Partials/DisplayConsentValues';

import SwitchInput from '@app/partials/content/SwitchInput';
import Modal from '@app/partials/HoC/Modal';

import { GDPR_FIELDS } from '../Helpers/Customer';

const Consent = ({ consent, canUpdate, updateConsents, hasEmail }) => {
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    const Intl = useIntl();
    const methods = useForm();
    const { handleSubmit } = methods;

    useEffect(() => {
        if (!canUpdate) {
            setIsUpdateMode(false);
        }
    }, [canUpdate]);

    const onSubmit = (data) => {
        updateConsents(data);
    };

    const handleModalConfirmation = () => {
        // if the contact has an email, we need to display the modal consent
        if (hasEmail) {
            setIsModalConfirmOpen(true);
        } else {
            handleSubmit(onSubmit)();
        }
    };

    const handleModalConfirmSuccess = () => {
        setIsModalConfirmOpen(false);
        handleSubmit(onSubmit)();
    };

    return (
        <div className="d-flex flex-column mt-4">
            <span className={`font-weight-bold ${KTUtil.isMobileDevice() && 'd-flex align-items-center'}`}>
                {consent?.name?.toUpperCase()}
                {!isUpdateMode && canUpdate && KTUtil.isMobileDevice() && (
                    <Button className="ml-2 p-0" onClick={() => setIsUpdateMode(!isUpdateMode)} variant="outline-link">
                        <i className="la la-pen p-0 text-muted" />
                    </Button>
                )}
            </span>
            {isUpdateMode ? (
                <FormProvider {...methods}>
                    <div className="mt-2">
                        <div
                            className={`d-flex justify-content-between mb-4${
                                KTUtil.isMobileDevice() ? ' flex-wrap' : ''
                            }`}
                        >
                            {Object.values(GDPR_FIELDS).map((gdprField, gdprFieldIdx) => (
                                <div
                                    key={gdprField.slug}
                                    className={classNames('mh-100', {
                                        'w-100': KTUtil.isDesktopDevice(),
                                        'w-50': KTUtil.isMobileDevice(),
                                        'mr-4': KTUtil.isDesktopDevice() && gdprFieldIdx + 1 !== gdprField,
                                    })}
                                >
                                    <SwitchInput
                                        name={`new_consents.${consent?.refDealership}_${gdprField.slug}`}
                                        active={consent[gdprField.slug]}
                                        labelFirst=""
                                        className="h-100 mr-1"
                                        labelSecond={Intl.formatMessage({ id: gdprField.label })}
                                    />
                                </div>
                            ))}
                        </div>
                        <div>
                            <div className="float-right">
                                <Button
                                    variant="outline-secondary"
                                    className="mr-5"
                                    style={{ width: '80px' }}
                                    onClick={() => setIsUpdateMode(false)}
                                >
                                    {Intl.formatMessage({ id: `TRANSLATOR.CANCEL` })}
                                </Button>
                                <Button
                                    variant="primary"
                                    style={{ width: '80px' }}
                                    type="button"
                                    onClick={handleModalConfirmation}
                                >
                                    {Intl.formatMessage({ id: `TRANSLATOR.UPDATE` })}
                                </Button>

                                <Modal
                                    As={ConsentConfirmation}
                                    showModal={isModalConfirmOpen}
                                    setShowModal={setIsModalConfirmOpen}
                                    onConfirm={handleModalConfirmSuccess}
                                    onCancel={() => setIsUpdateMode(false)}
                                />
                            </div>
                        </div>
                    </div>
                </FormProvider>
            ) : (
                <div>
                    <div
                        className={classNames('d-flex mt-2', {
                            'justify-content-between': KTUtil.isMobileDevice(),
                            'flex-wrap': KTUtil.isMobileDevice(),
                        })}
                    >
                        <DisplayConsentValues consent={consent} />
                        {canUpdate && KTUtil.isDesktopDevice() && (
                            <Button
                                className="ml-2 p-0"
                                onClick={() => setIsUpdateMode(!isUpdateMode)}
                                variant="outline-link"
                            >
                                <i className="la la-pen p-0 text-muted" />
                            </Button>
                        )}
                    </div>
                    {!canUpdate && consent?.name === 'AUTOSPHERE' && (
                        <FormattedHTMLMessage id="CUSTOMER.SIDEBAR_CONSENT.BEHAVIOUR.AFR_UPDATE_CONSENTS" />
                    )}
                </div>
            )}
            {consent?.name === 'autosphere' && (
                <div className="mt-2">{Intl.formatMessage({ id: 'CUSTOMERS.CONSENT.AUTOSPHERE.NOT.UPDATABLE' })}</div>
            )}
        </div>
    );
};

Consent.defaultProps = {
    canUpdate: false,
};

export default Consent;
