import dayjs from 'dayjs';
import React, { ReactNode } from 'react';

import KTUtil from '@src/_metronic/_assets/js/util';

import { LeadReason } from '@app/crud/customers/customer.type';

import TextOverflow from '@app/components/formatters/text/TextOverflow';

import LeadStatusRow from './LeadStatusRow';

interface Props {
    statusName: string;
    statusSlug?: string;
    statusSubName?: string;
    leadStatusUpdated?: string;
    leadReason?: LeadReason;
    leadComment?: string;
    table?: boolean;
    divider?: ReactNode;
    className?: string;
    overridedStatus?: string;
}
const StatusLead = ({
    statusSlug,
    statusName,
    statusSubName,
    leadStatusUpdated,
    leadReason,
    leadComment,
    table,
    divider,
    className,
    overridedStatus,
}: Props) =>
    statusSlug || statusName ? (
        <>
            <LeadStatusRow
                leadStatusSlug={statusSlug}
                leadStatusName={statusName}
                leadStatusSubName={statusSubName}
                shift=""
                divider={divider}
                className={className}
                overridedStatus={overridedStatus}
            />
            {!KTUtil.isInResponsiveRange('mobile') && (
                <div className="font-size-sm" style={{ maxWidth: '300px' }}>
                    <strong>{leadReason?.name}</strong>
                    {leadComment && leadComment.trim() !== '' && (
                        <div className="d-flex">
                            &laquo;
                            <TextOverflow content={leadComment} lines={2} className="font-italic" />
                            <span className="align-self-end">&raquo;</span>
                        </div>
                    )}
                </div>
            )}
            {table && leadStatusUpdated && leadStatusUpdated !== 'XXXXX' && (
                <span className="status-date">{dayjs(leadStatusUpdated).format('DD/MM/YYYY [à] HH [h] mm')}</span>
            )}
        </>
    ) : (
        <>-</>
    );

StatusLead.defaultProps = {
    divider: <br className="d-block d-xxl-none" />,
    className: 'd-block d-xxl-flex align-items-center flex-wrap',
    statusSlug: '',
    statusSubName: '',
    leadStatusUpdated: '',
    leadReason: {},
    leadComment: '',
    table: false,
    overridedStatus: '',
};

export default StatusLead;
