import classNames from 'classnames';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import KTUtil from '@src/_metronic/_assets/js/util';
import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { bernardConsentUpdateRequest } from '@app/crud/customers/customer.crud';
import { Customer } from '@app/crud/customers/customer.type';

import DisplayConsentValues from '@app/pages/customers/Partials/DisplayConsentValues';

import ConfirmModal from '@app/partials/content/modals/ConfirmModal';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import toast from '@app/partials/content/Toast';

import useModal from '../../../hooks/useModal';

interface BernardConsentBlockProps {
    contact: Customer;
}

const BernardConsentBlock = ({ contact }: BernardConsentBlockProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showConfirmModal, toggleConfirmModal] = useModal(false, 'confirm');
    const consent = contact?.consents?.find((c) => c.slug === 'autobernard');
    const hasValidConsent =
        consent &&
        (consent.gdprSms !== null ||
            consent.gdprPhone !== null ||
            consent.gdprPost !== null ||
            consent.gdprEmail !== null);

    const sendBernardConsentUpdateRequest = () => {
        setLoading(true);
        bernardConsentUpdateRequest(contact.id)
            .then((response) => {
                if (response?.result?.success === true) {
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'CUSTOMER.CONSENT.UPDATE_REQUEST.SUCCESS' }),
                    });
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'CUSTOMER.CONSENT.UPDATE_REQUEST.FAILED' }),
                    });
                }
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMER.CONSENT.UPDATE_REQUEST.FAILED' }),
                });
            })
            .finally(() => {
                setLoading(false);
                toggleConfirmModal(false);
            });
    };

    return (
        <>
            <div>
                <p className="font-weight-bold">
                    <FormattedHTMLMessage id="CUSTOMER.CONSENT" />
                    &nbsp;:
                </p>
                {hasValidConsent ? (
                    <div
                        className={classNames('d-flex mt-3 justify-content-center', {
                            'flex-wrap': KTUtil.isMobileDevice(),
                        })}
                    >
                        <DisplayConsentValues consent={consent} hideNull />
                    </div>
                ) : (
                    <FormattedHTMLMessage id="CUSTOMER.CONSENT.NOT_FOUNDED" />
                )}
                <div className="d-flex justify-content-center">
                    <OverlayTooltip
                        label={
                            !contact?.email
                                ? `CUSTOMER.CONSENT.UPDATE_REQUEST.DISABLED.TOOLTIP`
                                : `CUSTOMER.CONSENT.UPDATE_REQUEST.TOOLTIP`
                        }
                        id="bernard_consent"
                        position="bottom"
                    >
                        <div>
                            <Button
                                variant="primary"
                                className="mt-5"
                                onClick={() => toggleConfirmModal()}
                                disabled={!contact?.email}
                            >
                                <FormattedMessage id="CUSTOMER.CONSENT.UPDATE_REQUEST" />
                            </Button>
                        </div>
                    </OverlayTooltip>
                </div>
            </div>
            <ConfirmModal
                icon={<i className="las la-2x la-envelope" />}
                title={<FormattedHTMLMessage id="CUSTOMER.CONSENT.UPDATE_REQUEST.TITLE" />}
                description={<FormattedHTMLMessage id="CUSTOMER.CONSENT.UPDATE_REQUEST.DESCRIPTION" />}
                showModal={showConfirmModal}
                setShowModal={toggleConfirmModal}
                onConfirm={sendBernardConsentUpdateRequest}
                onCancel={() => toggleConfirmModal(false)}
                loading={loading}
            />
        </>
    );
};

export default BernardConsentBlock;
