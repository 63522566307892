import React from 'react';
import { FormattedMessage } from 'react-intl';

import './Consent.scss';

import KTUtil from '@src/_metronic/_assets/js/util';

import { Consent } from '@app/store/customers/customers.store';

interface DisplayConsentValuesProps {
    consent: Consent;
    hideNull?: boolean; // Hide phone and postal consent if null specific bernard
}
const DisplayConsentValues = ({ consent, hideNull = false }: DisplayConsentValuesProps) => (
    <>
        {((hideNull && consent?.gdprEmail !== null) || !hideNull) && (
            <span
                className={`label label-inline label-outline-${
                    consent?.gdprEmail ? 'success ' : 'danger'
                } px-3 py-1 consent-value ${KTUtil.isMobileDevice() ? 'mb-2' : 'mr-2'}`}
                {...(KTUtil.isMobileDevice() && { style: { flexBasis: '48%' } })}
            >
                <i className="la la-lg la-envelope mr-1" />
                <FormattedMessage id="TRANSLATOR.CONSENT_EMAIL" />
            </span>
        )}
        {((hideNull && consent?.gdprPhone !== null) || !hideNull) && (
            <span
                className={`label label-inline label-outline-${
                    consent?.gdprPhone ? 'success' : 'danger'
                } px-3 py-1 consent-value ${KTUtil.isMobileDevice() ? 'mb-2' : 'mr-2'}`}
                {...(KTUtil.isMobileDevice() && { style: { flexBasis: '48%' } })}
            >
                <i className="la la-lg la-mobile mr-1" />
                <FormattedMessage id="TRANSLATOR.CONSENT_PHONE" />
            </span>
        )}
        {((hideNull && consent?.gdprSms !== null) || !hideNull) && (
            <span
                className={`label label-inline label-outline-${
                    consent?.gdprSms ? 'success' : 'danger'
                } px-3 py-1 consent-value ${KTUtil.isMobileDevice() ? 'mb-2' : 'mr-2'}`}
                {...(KTUtil.isMobileDevice() && { style: { flexBasis: '48%' } })}
            >
                <i className="la la-lg la-comments mr-1" />
                <FormattedMessage id="TRANSLATOR.CONSENT_SMS" />
            </span>
        )}
        {((hideNull && consent?.gdprPost !== null) || !hideNull) && (
            <span
                className={`label label-inline label-outline-${
                    consent?.gdprPost ? 'success' : 'danger'
                } px-3 py-1 consent-value ${KTUtil.isMobileDevice() ? 'mb-2' : 'mr-2'}`}
                {...(KTUtil.isMobileDevice() && { style: { flexBasis: '48%' } })}
            >
                <i className="la la-lg la-file-alt mr-1" />

                <FormattedMessage id="TRANSLATOR.CONSENT_POST" />
            </span>
        )}
    </>
);
export default DisplayConsentValues;
